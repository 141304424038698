<template>
  <footer
    :key="key"
    class="bg-white text-green-swamp border-t-0 md:border-t border-gray"
    aria-labelledby="footer-heading"
  >
    <div
      class="container flex justify-between flex-col pt-[30px] pb-[38px] md:py-[50px] md:flex-row"
    >
      <!--      <div class="md:mb-0 mb-8">
        <div class="footer-section-header">
          {{ t('Контакти') }}
        </div>
        <AppHeaderContactsMenuBlock larger-mobile />
      </div>-->
      <AppFooterLinksSection :title="t('Каталог')">
        <div class="grid grid-cols-2 md:flex md:gap-8 xl:gap-14">
          <AppFooterListOfLinks :items="catalogCategories" />
          <!--          <AppFooterListOfLinks :items="navigation.catalog.secondColumn" />-->
        </div>
      </AppFooterLinksSection>
      <AppFooterLinksSection :title="t('Cервіси ІБІС')">
        <AppFooterListOfLinks :items="navigation.service" responsive />
      </AppFooterLinksSection>
      <AppFooterLinksSection :title="t('Підтримка')">
        <AppFooterListOfLinks :items="navigation.support" responsive />
      </AppFooterLinksSection>
      <AppFooterLinksSection :title="t('Про IБІС')">
        <AppFooterListOfLinks :items="navigation.company" responsive />
      </AppFooterLinksSection>
      <div>
        <div class="footer-section-header">
          {{ t('Ми в соцмережах') }}
        </div>
        <AppFooterSocialLinks />
      </div>
    </div>
    <AppFooterBottomBanner />
  </footer>
</template>

<script setup>
import { useMenuStore } from '~/stores/menu'
import ProductVariantEntity from '~/entities/ProductVariant.entity'

const { t, locale } = useI18n()

const key = ref(null)
const menuStore = useMenuStore()

const catalogCategories = computed(() => {
  const categories = menuStore.categoriesList
    .map(item => {
      item.href = ProductVariantEntity.buildCatalogCategoryLink({
        categorySlug: item.slug,
        hostname: item.channelHostname,
        locale: locale.value
      })
      return item
    })
    .filter(item => item.slug !== 'ibis-gear-brendi')
  categories.push(
    // { name: t('Бренди'), href: '/brand' },
    { name: t('Акції та Новинки'), href: '/post' }
  )

  return categories
})

const navigation = ref({
  catalog: {
    secondColumn: [
      // { name: t('Магазини'), href: '/contacts#shop-addresses' },
      // { name: t('Бренди'), href: '/brand' },
      { name: t('Акції та Новинки'), href: '/post' }
    ]
  },
  support: [
    // { name: t('Відстеження замовлення'), href: '/tracking' },
    { name: t('Доставка і оплата'), href: '/dostavka-i-oplata' },
    { name: t('Питання та відповіді'), href: '/faq' },
    { name: t('cookie_policy'), href: '/cookies-policy' },
    { name: t('user_agreement'), href: '/uhoda-korystuvacha' },
    { name: t('public_reporting'), href: '/publichna-zvitnist' },
    { name: t('Контакти'), href: '/contacts' }
    // { name: t('Дисконтна програма'), href: '/dk' }
    // { name: t('Звʼязатись з нами'), href: '#' } // на кнопку звʼязатися з нами
  ],
  service: [
    { name: t('Сервіс-центри ІБІС'), href: '/servisnyy-centr-ibis' },
    { name: t('menu_generator_service'), href: '/servis-generatoriv' },
    { name: t('menu_reloading_program'), href: '/programa-relodingu' },
    {
      name: t('fishing_workshops'),
      href: '/servisnyy-centr-favorite-select-brain'
    },
    { name: t('lazerne_graviiuvannia'), href: '/post/lazerne-graviiuvannia/' }
  ],
  company: [
    { name: t('Про компанію'), href: '/about' },
    { name: t('Магазини'), href: '/contacts#shop-addresses' },
    {
      name: t('Тир і стрілецький клуб'),
      href: 'https://ibis-club.com.ua/',
      external: true
    },
    // { name: t('База знань від ІБІС'), href: '#' },
    { name: t('Сервісні центри'), href: '/servisnyy-centr-ibis' },
    // { name: t('Турніри'), href: '#' },
    // { name: t('Оптова торгівля'), href: '#' },
    { name: t('КУЛДІМ'), href: 'https://kuldim.com/', external: true }
  ]
})
onMounted(() => {
  key.value = Date.now().toString()
})
</script>

<style lang="postcss" scoped>
.footer-section-header {
  @apply font-bold text-sm uppercase md:mb-6 mb-[14px];
}
</style>
