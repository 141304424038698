<template>
  <div
    v-show="show"
    class="grid gap-4 grid-cols-4 lg:grid-cols-5 2xl:grid-cols-6"
  >
    <div
      v-for="child in props.children"
      :key="child.id"
      class="mb-4 flex flex-col gap-2 category_col"
      @mouseleave="toggleTrigger"
    >
      <NuxtLink
        :key="child.name"
        :to="
          ProductVariantEntity.buildCatalogCategoryLink({
            categorySlug,
            subCategorySlug: child.slug,
            hostname: child?.channelHostname,
            locale,
            relationSlug: props.relationSlug
          })
        "
        class="font-bold leading-[19px] hover:text-primary hover:cursor-pointer uppercase"
      >
        {{ child.name }}
      </NuxtLink>

      <div
        v-if="child.children?.length"
        class="flex flex-col gap-2 text-[13px] leading-3.5"
      >
        <CategoryMenuSubChildren
          :child="child"
          :relation-slug="props.relationSlug"
          :is-show-more="props.children.length > 5"
          :trigger="trigger"
        />
        <template v-if="child?.slug === 'vohnepalna-zbroia'">
          <NuxtLink
            v-for="tuningPage in tuningPages"
            :key="tuningPage.name"
            :to="localePath(tuningPage.url)"
            class="hover:text-primary hover:cursor-pointer"
          >
            <span class="block text-wrap">{{ $t(tuningPage.name) }}</span>
          </NuxtLink>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import CategoryMenuSubChildren from './CategoryMenuSubChildren.vue'
import ProductVariantEntity from '~/entities/ProductVariant.entity'

const { locale } = useI18n()

const props = defineProps({
  show: {
    type: Boolean,
    default: false
  },
  children: {
    type: Array,
    default: () => []
  },
  relationSlug: {
    type: String,
    default: () => ''
  }
})

const categorySlug = inject('categorySlug')

const tuningPages = [
  { name: 'tuning_ar', url: '/tuning_ar/' },
  { name: 'tuning_ak', url: '/tuning_ak/' }
]

const trigger = ref(false)

const toggleTrigger = () => {
  trigger.value = !trigger.value
}
</script>

<style>
.category_col {
  padding: 10px;
  height: min-content;
}
.category_col:hover {
  background-color: #eff3f3;
  border-radius: 5px;
}
</style>
