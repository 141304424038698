<template>
  <div>
    <div :key="key" class="bg-white md:isolate z-10 md:fixed md:w-full z-[200]">
      <TheBanner v-if="showTopBanner" />

      <!-- DESKTOP -->
      <div class="border-b border-gray hidden lg:block">
        <div></div>
        <div class="container h-[69px] flex items-center">
          <div
            class="flex flex-1 h-16 items-center justify-between gap-4 relative"
          >
            <NuxtLink
              :to="localePath('/')"
              :aria-label="t('На головну сторінку')"
            >
              <IconsLogo class="h-[37px]" />
            </NuxtLink>
            <AppSiteToggle class="mr-auto ml-0" />
            <AppHeaderSearch class="flex" />
            <AppFormSelect
              v-model="lang"
              :options="i18n.locales.value"
              class="block"
              :get-label="v => v.name"
              :get-value="v => v.code"
            />

            <AppHeaderContactsMenu />
            <!--      start eSputnik-app-inbox     -->
            <div
              v-if="
                useAuthStore().loggedIn && storeType?.isHunting && !isMobile
              "
              id="es-app-inbox"
              class="block link-green hover:cursor-auto w-[23px] h-[23px]"
            ></div>
            <!--      end eSputnik-app-inbox     -->
            <NuxtLink
              v-if="!useAuthStore().loggedInStore"
              :to="localePath('/cabinet')"
              class="block link-green"
            >
              <IconsUser class="h-[23px]" />
            </NuxtLink>
            <div v-else class="py-3">
              <span
                id="sign-out-store-btn"
                class="flex items-center gap-3 text-green-ibis text-xs cursor-pointer w-fit hover:text-orange"
                style="color: white"
                @click="useAuthStore().signOutStore"
              >
                <ArrowRightOnRectangleIcon class="h-6" />
                <!--                {{ t('Вийти') }}-->
              </span>
            </div>
            <AppHeaderComparisonMenu class="block" />
            <NuxtLink
              :to="localePath('/cabinet/favorites')"
              class="block link-green"
            >
              <IconsHeart class="h-[23px] w-[23px]" :count="totalInWishlists" />
            </NuxtLink>
            <AppCartButton class="link-green" />
          </div>
        </div>
      </div>
      <!-- MOBILE -->
      <div
        class="border-b border-gray block lg:hidden"
        :class="{ 'mt-[0px]': showTopBanner }"
      >
        <div class="container h-[51px] flex items-center">
          <div class="flex flex-1 h-16 items-center justify-between gap-4">
            <NuxtLink :to="localePath('/')">
              <IconsLogo class="h-6" />
            </NuxtLink>
            <AppSiteToggle class="mx-auto" />
            <AppHeaderContactsMenu />
          </div>
        </div>
      </div>
      <AppHeaderNav />
    </div>

    <!-- placeholder -->
    <div
      class="hidden md:block h-[114px]"
      :class="{ 'h-[147px]': showTopBanner }"
    ></div>
  </div>
</template>

<script setup>
import { ArrowRightOnRectangleIcon } from '@heroicons/vue/24/outline'
import { useUserStore } from '~~/stores/user'
import { useAuthStore } from '~/stores/auth'

const storeType = useStoreType()
const { t } = useI18n()
const i18n = useI18n()
const lang = useLang()
const isMobile = useMediaQuery('(max-width: 767px)')
const showTopBanner = ref(false)

const key = ref(null)
onMounted(() => {
  key.value = Date.now().toString()
})

const totalInWishlists = computed(() => useUserStore().totalInWishlists || 0)
</script>

<style>
#headlessui-portal-root {
  z-index: 205;
}
</style>
