<template>
  <div class="h-full overflow-y-auto bg-white py-2.5 flex flex-col">
    <div class="px-2.5">
      <DialogTitle
        as="a"
        class="flex justify-start items-center gap-2.5 border-b h-[57px] px-[11px] hover:text-primary hover:cursor-pointer"
        @click="handleBack"
      >
        <ArrowLeftIcon class="h-3.5" />
        {{ t('Каталог товарів') }}
      </DialogTitle>
    </div>
    <nav aria-label="Sidebar" class="relative text-sm flex-1 px-2.5 flex">
      <ul class="uppercase flex flex-col flex-1 divide-y-px">
        <template v-for="item in allCategories" :key="item.slug">
          <li :class="[item.class || '']">
            <NuxtLink
              :to="localePath(item.href) || localePath(item?.categoryLink())"
              class="flex items-center justify-between h-10 gap-3.5 px-[11px] hover:text-primary"
            >
              {{ item.name }}
              <ChevronRightIcon class="h-3.5" />
            </NuxtLink>
          </li>
        </template>
        <div v-if="storeType?.isHunting">
          <li v-for="tuningPage in tuningPages" :key="tuningPage.name">
            <NuxtLink
              :to="localePath(tuningPage.url)"
              class="flex items-center justify-between h-10 gap-3.5 px-[11px] hover:text-primary"
            >
              {{ $t(tuningPage.name) }}
            </NuxtLink>
          </li>
        </div>
      </ul>
    </nav>
  </div>
</template>

<script setup>
import { DialogTitle } from '@headlessui/vue'
import { ChevronRightIcon } from '@heroicons/vue/24/outline'
import { ArrowLeftIcon } from '@heroicons/vue/24/solid'
import { useMenuStore } from '~/stores/menu'
import TaxonEntity from '~~/entities/Taxon.entity'

const storeType = useStoreType()
// const selectedCategory = ref(null)
const { t } = useI18n()
const allCategories = computed(() =>
  useMenuStore().categoriesList.map(c => new TaxonEntity(c))
)
const emits = defineEmits('back')

const tuningPages = [
  { name: 'tuning_ar', url: '/tuning_ar/' },
  { name: 'tuning_ak', url: '/tuning_ak/' }
]

const handleBack = () => {
  emits('back')
}
</script>
