<template>
  <div
    v-for="category in categories"
    :key="category.id"
    class="flex text-xs border-b border-gray hover:bg-gray-200 p-2 pr-5 justify-between items-center min-h-[10px] cursor-pointer"
    :class="category.id === selectedCategory ? 'bg-gray-200' : ''"
    @click="emit('selectCategory', category)"
  >
    <nuxt-link
      v-if="category.id !== '0'"
      :to="
        localePath(
          `/search/?searchstring=${category.name}&cat_id=${category.id}`
        )
      "
      class="flex break-words items-center w-full"
    >
      <FolderOpenIcon
        v-if="category.id === selectedCategory"
        class="w-3 mr-2 text-gray-400"
      />
      <FolderIcon v-else class="w-3 mr-2 text-gray-400" />
      {{ category.name }}
      <IconsArrowRight class="w-3 ml-2 text-green-ibis" />
    </nuxt-link>
    <nuxt-link
      v-else
      :id="`srch-category-all`"
      :to="localePath(`/search/?searchstring=${query}`)"
      class="flex break-words items-center w-full"
    >
      <FolderIcon class="w-3 mr-2 text-gray-400" />
      {{ category.name }}
      <IconsArrowRight class="w-3 ml-2 text-green-ibis" />
    </nuxt-link>
    <div>
      {{ category.count }}
    </div>
  </div>
</template>

<script setup>
import { FolderIcon, FolderOpenIcon } from '@heroicons/vue/20/solid'
import { useLocalePath } from '#imports'
const localePath = useLocalePath()

defineProps({
  categories: {
    type: Array,
    default: () => []
  },
  selectedCategory: {
    type: Number,
    default: () => 0
  },
  query: {
    type: String,
    default: ''
  }
})

const emit = defineEmits(['selectCategory'])
</script>
